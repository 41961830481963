import React, { useState, useEffect, useRef } from "react";
import QrReader from "react-qr-scanner";
import { FaTicketAlt, FaArrowLeft, FaQrcode } from "react-icons/fa";

const Scanner = () => {
	const [result, setResult] = useState("No result");
	const [ticketDetails, setTicketDetails] = useState(null);
	const [scanning, setScanning] = useState(true);
	const [isProcessing, setIsProcessing] = useState(false);
	const lastScannedRef = useRef(null);

	useEffect(() => {
		// Redirect to login if token is not set or expired
		const token = localStorage.getItem("token");
		const expiresIn = localStorage.getItem("expiresIn");

		if (!token || Date.now() >= expiresIn) {
			window.location.href = "/login";
		}
	}, []);

	const handleScan = async (data) => {
		// Ignore if there's no data or already processing a scan
		if (!data?.text || isProcessing) {
			return;
		}

		// Avoid processing the same QR code multiple times
		if (lastScannedRef.current === data.text) {
			return;
		}

		// Lock scanning and update UI state
		setIsProcessing(true);
		lastScannedRef.current = data.text;
		setResult(data.text);
		setScanning(false);

		const ticketId = data.text;
		const token = localStorage.getItem("token");
		const apiUrl = `https://hangoverparties.sk/api/tickets/${ticketId}`;

		const headers = {
			"Content-Type": "application/json",
			Authorization: token,
		};

		try {
			// Step 1: Get current ticket information
			const ticketResponse = await fetch(apiUrl, {
				method: "GET",
				headers,
			});

			if (!ticketResponse.ok) {
				throw new Error(`Ticket not found (Status ${ticketResponse.status})`);
			}

			const ticketData = await ticketResponse.json();
			console.log("Ticket data:", ticketData);

			// Step 2: If ticket hasn't been scanned yet, mark it as scanned
			if (!ticketData.scannedAt) {
				console.log("Marking ticket as scanned");

				const updateResponse = await fetch(apiUrl, {
					method: "PATCH",
					headers,
					body: JSON.stringify({ scanned: true }),
				});

				if (!updateResponse.ok) {
					throw new Error(`Failed to update ticket (Status ${updateResponse.status})`);
				}
			}
			setTicketDetails(ticketData);
		} catch (error) {
			console.error("Ticket processing error:", error);

			// Show more specific error messages
			if (error.message.includes("Ticket not found")) {
				alert("Invalid ticket: Not found in the system");
			} else if (error.message.includes("Failed to update")) {
				alert("Could not mark ticket as scanned. Please try again.");
			} else {
				alert(`Error: ${error.message || "Unknown error occurred"}`);
			}

			// Reset scanner on error
			setResult("No result");
			setScanning(true);
		} finally {
			setIsProcessing(false);
		}
	};

	const handleError = (err) => {
		console.error("QR Scan Error:", err);
	};

	return (
		<div className="p-4">
			{/* <h1 className="p-4 text-2xl font-bold text-center">Ticket Scanner</h1> */}

			<div className="rounded-lg overflow-hidden shadow-lg">
				{scanning && (
					<QrReader
						delay={300}
						onError={handleError}
						onScan={handleScan}
						style={{ width: "100%" }}
						constraints={{
							video: { facingMode: "environment" }, // Prefer rear camera if available
						}}
					/>
				)}
			</div>

			{result !== "No result" && ticketDetails && (
				<div className="popup p-4 shadow-lg rounded-md bg-gray-100 mt-4">
					<h2 className="text-lg font-semibold text-center">Ticket Details</h2>
					<hr className="my-2" />
					<p>
						<strong>Ticket ID:</strong> <span className="font-mono">{ticketDetails.id}</span>
					</p>
					<p>
						<strong>Event:</strong> {ticketDetails.eventId}
					</p>
					<p>
						<strong>Type:</strong> {ticketDetails.ticketTypeName}
					</p>
					{ticketDetails.scannedAt ? (
						<div className="bg-red-600 p-4 rounded-lg mt-5 text-white">
							<h2 className="text-center font-bold text-xl p-2">Ticket uz naskenovany</h2>
							<p>
								<strong>Scanned At:</strong>{" "}
								<span className="font-mono">{new Date(ticketDetails.scannedAt).toLocaleString()}</span>
							</p>
						</div>
					) : (
						<div className="bg-green-600 p-4 rounded-lg mt-5 text-white">
							<h2 className="text-center font-bold text-xl p-2">Ticket je valid</h2>
						</div>
					)}
				</div>
			)}

			<button
				onClick={() => {
					lastScannedRef.current = null;
					setResult("No result");
					setTicketDetails(null);
					setScanning(true);
					setIsProcessing(false);
				}}
				className="bg-purple-600 hover:bg-purple-700 text-white mt-4 font-bold text-lg rounded-lg p-4 w-full shadow-md transition-all duration-200 flex justify-center items-center"
				disabled={isProcessing}
			>
				<FaQrcode className="mr-2" />
				{isProcessing ? "Processing..." : "Scan next"}
			</button>
			<button
				onClick={() => (window.location.href = "/")}
				className="bg-gray-600 hover:bg-gray-700 text-white font-bold text-lg rounded-lg p-4 w-full shadow-md transition-all duration-200 flex justify-center items-center mt-4"
				disabled={isProcessing}
			>
				<FaArrowLeft className="mr-2" />
				Back
			</button>
		</div>
	);
};

export default Scanner;
