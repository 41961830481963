import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
// Import icons
import { FaRegCalendarAlt, FaTicketAlt, FaUserPlus, FaStar, FaSignOutAlt } from "react-icons/fa";

function Home() {
	const [MyEvent, setMyEvent] = useState({});
	const [Tickets, setTickets] = useState([]);
	const [scannedTickets, setScannedTickets] = useState([]);
	const [totalTickets, setTotalTickets] = useState(0);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		// Redirect to login if token is not set or expired
		const token = localStorage.getItem("token");
		const expiresIn = localStorage.getItem("expiresIn");

		if (!token || Date.now() >= expiresIn) {
			window.location.href = "/login";
		}

		// get myEvent from hangoverparties.sk/events/upcoming
		const getEvent = async () => {
			try {
				const response = await fetch("https://hangoverparties.sk/api/events/upcoming");
				const data = await response.json();
				setMyEvent(data);
				fetch("https://hangoverparties.sk/api/tickets?eventId=" + data.id, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: localStorage.getItem("token"),
					},
				})
					.then((response) => response.json())
					.then(async (ticketData) => {
						// Fetch transaction status for each ticket
						const ticketsWithTransactions = await Promise.all(
							ticketData.map(async (ticket) => {
								if (ticket.transactionId) {
									const transResponse = await fetch(
										`https://hangoverparties.sk/api/transactions/${ticket.transactionId}`,
										{
											headers: {
												Authorization: localStorage.getItem("token"),
											},
										}
									);
									const transData = await transResponse.json();
									return { ...ticket, paid: transData.state === "PAID" };
								}
								return { ...ticket, paid: false };
							})
						);

						// Filter only paid tickets
						const paidTickets = ticketsWithTransactions.filter((ticket) => ticket.paid);
						setTickets(paidTickets);
						setTotalTickets(paidTickets.length);
						const scanned = paidTickets.filter((ticket) => ticket.scannedAt);
						setScannedTickets(scanned);
						console.log("Tickets scanned:", scanned.length);
						console.log("Total paid tickets:", paidTickets.length);
						setLoading(false);
					})
					.catch((error) => {
						console.error("Error fetching ticket:", error);
						setLoading(false);
					});
			} catch (error) {
				console.error("Error getting event data:", error);
				setLoading(false);
			}
		};

		// checkCameraPermission();
		getEvent();
	}, []);

	// Helper function to get icon for ticket type
	const getTicketIcon = (type) => {
		if (type?.toLowerCase().includes("vip")) return <FaStar className="text-yellow-500" />;
		if (type?.toLowerCase().includes("plus")) return <FaUserPlus className="text-blue-500" />;
		return <FaTicketAlt className="text-gray-600" />;
	};

	return (
		<div className="flex flex-col min-h-screen p-4 pt-16 bg-gradient-to-b from-gray-50 to-gray-100">
			<h1 className="w-full text-center text-3xl font-bold mb-6 text-gray-800">Hangover Scanner</h1>
			<div className="flex flex-col justify-around p-6 items-center">
				<div className="flex flex-col justify-center items-center bg-white w-full p-6 rounded-lg shadow-md mb-6 border border-gray-200">
					<div className="flex items-center mb-3 text-gray-800">
						<FaRegCalendarAlt className="mr-2 text-purple-600" />
						<h2 className="font-bold text-xl">Event: {MyEvent.name}</h2>
					</div>

					<div className="w-full">
						<h3 className="font-bold text-lg text-gray-800 mb-2">
							Tickets:{" "}
							<span className="text-purple-600">
								{loading ? "Loading..." : `${scannedTickets.length} / ${totalTickets}`}
							</span>
						</h3>
						<div className="mt-3 space-y-2">
							{!loading &&
								Object.entries(
									Tickets.reduce((acc, ticket) => {
										acc[ticket.ticketTypeName] = (acc[ticket.ticketTypeName] || 0) + 1;
										return acc;
									}, {})
								).map(([type, count]) => {
									const scannedCount = scannedTickets.filter(
										(ticket) => ticket.ticketTypeName === type
									).length;
									// Fix typo in "Standart Plus" if it exists
									const displayType = type === "Standart Plus" ? "Standard Plus" : type;
									return (
										<div
											key={type}
											className="flex items-center text-base text-gray-700 font-medium py-1 border-b border-gray-100"
										>
											{getTicketIcon(displayType)}
											<span className="ml-2">{displayType}:</span>
											<span className="ml-auto font-semibold">
												{scannedCount}/{count}
											</span>
										</div>
									);
								})}
						</div>
					</div>
				</div>

				<Link to="/scan" className="w-full">
					<button className="bg-purple-600 hover:bg-purple-700 text-white font-bold text-lg rounded-lg p-4 w-full shadow-md transition-all duration-200 flex justify-center items-center">
						<FaTicketAlt className="mr-2" />
						Scan Ticket
					</button>
				</Link>
			</div>

			{/* Spacer to push logout button to bottom */}
			<div className="flex-grow"></div>

			{/* logout button */}
			<div className="flex justify-center mb-10 mt-6">
				<button
					className="px-6 py-2 text-red-600 font-semibold flex items-center hover:bg-red-50 rounded-lg transition-colors duration-200"
					onClick={() => {
						localStorage.removeItem("token");
						window.location.href = "/login";
					}}
				>
					<FaSignOutAlt className="mr-2" />
					Logout
				</button>
			</div>
		</div>
	);
}

export default Home;
